@keyframes ldio-w5kaspytpj {
  0% { transform: translate(-50%,-50%) rotate(0deg); }
  100% { transform: translate(-50%,-50%) rotate(360deg); }
}
.ldio-w5kaspytpj div {
  position: absolute;
  width: 120px;
  height: 120px;
  border: 20px solid #32cd32;
  border-top-color: transparent;
  border-radius: 50%;
}
.ldio-w5kaspytpj div {
  animation: ldio-w5kaspytpj 1s linear infinite;
  top: 100px;
  left: 100px
}
.loadingio-spinner-rolling-tbkf2ce9si {
  width: 200px;
  height: 200px;
  display: inline-block;
  overflow: hidden;
  background: none;
}
.ldio-w5kaspytpj {
  width: 100%;
  height: 100%;
  position: relative;
  transform: translateZ(0) scale(1);
  backface-visibility: hidden;
  transform-origin: 0 0; /* see note above */
}
.ldio-w5kaspytpj div { box-sizing: content-box; }
/* generated by https://loading.io/ */