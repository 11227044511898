/* Market Order */

.checkbox {
    display: flex;
    accent-color: limegreen;
}
.checkboxContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 98%;
  margin: 5%;
}
.selectChainOrder{
  width: 100%;
  background-color: #202020;
  color: white;
  margin-bottom: 10%;
  font-size: 1.25em
}
.input {
  width: 70%;
  margin: 10% auto;
  background-color: #202020;
  color: #909090;
  text-align: center;
  font-size: 1.2em
}
.buttonContainer {
    display: flex;
    justify-content: space-evenly;
    margin: 0px auto;
    padding: 5%;
    width: 100%;
  }
.buyButton {
    background-color: green;
    color: black;
    height: 40px;
    width: 80px;
    border: solid 3px black;
    border-radius: 0.5rem;
  }
.sellButton {
    background-color: red;
    color: black;
    height: 40px;
    width: 80px;
    border: solid 3px black;
    border-radius: 0.5rem;
  }
  .checkboxes{
    width: 90%;
    flex: flex-start;
    text-align: left;
  }
  .cog {
    color: #51586f;
    transition: 0.3s;
  }
  
  .cog:hover {
    color: white;
    rotate: 90deg;
    cursor: pointer;
  }
  
  .table {
    border-collapse: collapse;
    width: 80%;
    float: left;
    font-size: .93m;
    box-shadow: 0 0 20px rgba(0,0,0, 0.15);
  }
  .table thead tr {
    background-color: black;
    color: lime;
    text-align: left;
    border-bottom: 3px solid lime;
}
.table th,
.table td {
    border-right: 1px solid #606060;
    border-left: 1px solid #606060;
}


.table tbody tr {
  border-bottom: 1px solid black;
  color: #F9F6EE;
  background-color: #202020;
}

.table tbody tr:nth-of-type(even) {
  background-color: #101010;
}

.table tbody tr:last-of-type {
  border-bottom: 2px solid black;
}
.table tbody tr.active-row {
  font-weight: bold;
  color: #009879;
}
.table tbody tr:hover {
  background-color: black;
  color: #F9F6EE; /* Adjust text color as needed */
}
.transactionPageButtons{
  margin: 0px auto;
  width: 60%;
  display: flex;
  justify-content: space-between;
  margin-top: 1vh;
}
.pageNumbers{
  display: flex;
  justify-content: space-between;
  width: 60%;
}
.pageNumber:hover{
  cursor: pointer;
}

.pageInput{
  width: 20%;
  text-align: center;
  background-color: #202020;
  color: #F9F6EE;
}
.pageInput[type="number"]::-webkit-inner-spin-button,
.pageInput[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.pageInput[type="number"] {
  -moz-appearance: textfield;
}
.pageButtons{
  background-color: lime;
  color: black;
  border: 3px solid black;
  border-radius: .5em;
}
/* TokenBlockChainDesc */
.modalExpandContainer{
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.8);
  z-index: 1000;
}
.modal-container {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: #101010;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  color: #f9f6ee;
  max-height: 80vh; 
  overflow-y: auto;
  z-index: 1001;
}

.modal-header {
  display: flex;
  justify-content: space-evenly;
  border-top: 2px solid lime;
  border-bottom: 2px dashed lime;
}

.modal-body {
  display: flex;
  align-items: center;
}

.address-container {
  border-radius: .5em;
  border: 1px solid #F9F6EE;
  padding: 2vw;
  margin-right: 1rem;
  display: flex;
  flex-direction: column;
  color: lime;
}

.admin-container {
  padding: 2vw;
}

.admin-container input {
  margin-bottom: 0.5em;
}

.supply-container {
  display: flex;
  justify-content: space-evenly;
  padding: 1vw;
  border-bottom: 2px solid lime;
}

.button-container {
  display: flex;
  justify-content: space-evenly;
  padding: 1vw;
}

.textarea-container {
  padding: 2vw;
}

.textArea{
  width: 80%;
  height: 15vh;
  resize: vertical;
  background-color: #303030;
  color: #F9F6EE;
}
.contractAddresses{
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: lime;
}
.button{
  border-radius: .5em;
  background-color: lime;
  border: 3px solid black;
  font: bold;
  font-size: 1.1em;
}
.inputBox{
  background-color: #303030;
  color:#F9F6EE;
  margin: 1vw;
}
.uuid{
  color:#F9F6EE;
  margin: 1vw;
}
/*toggle switch*/
.cl-toggle-switch {
  position: relative;
  margin: 1vw
 }
 
 .cl-switch {
  position: relative;
  display: inline-block;
  margin: 1vw;
 }
 /* Input */
 .cl-switch > input {
  appearance: none;
  -moz-appearance: none;
  -webkit-appearance: none;
  z-index: -1;
  position: absolute;
  right: 6px;
  top: -8px;
  display: block;
  margin: 0;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  background-color: #404040;
  outline: none;
  opacity: 0;
  transform: scale(1);
  pointer-events: none;
  transition: opacity 0.3s 0.1s, transform 0.2s 0.1s;
 }
 /* Track */
 .cl-switch > span::before {
  content: "";
  float: right;
  display: inline-block;
  margin: 5px 0 5px 10px;
  border-radius: 7px;
  width: 36px;
  height: 14px;
  background-color: #404040;
  vertical-align: top;
  transition: background-color 0.2s, opacity 0.2s;
 }
 /* Thumb */
 .cl-switch > span::after {
  content: "";
  position: absolute;
  top: 2px;
  right: 16px;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  background-color: #fff;
  box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
  transition: background-color 0.2s, transform 0.2s;
 }
 /* Checked */
 .cl-switch > input:checked {
  right: -10px;
  background-color: #707070;
 }
 
 .cl-switch > input:checked + span::before {
  background-color: #707070;
 }
 
 .cl-switch > input:checked + span::after {
  background-color: lime;
  transform: translateX(16px);
 }
 /* Hover, Focus */
 .cl-switch:hover > input {
  opacity: 0.04;
 }
 
 .cl-switch > input:focus {
  opacity: 0.12;
 }
 
 .cl-switch:hover > input:focus {
  opacity: 0.16;
 }

 /* Active */
 .cl-switch > input:active {
  opacity: 1;
  transform: scale(0);
  transition: transform 0s, opacity 0s;
 }
 
 .cl-switch > input:active + span::before {
  background-color: #8f8f8f;
 }
 
 .cl-switch > input:checked:active + span::before {
  background-color: #404040;
 }
 /* Disabled */
 .cl-switch > input:disabled {
  opacity: 0;
 }
 
 .cl-switch > input:disabled + span::before {
  background-color: #ddd;
 }
 
 .cl-switch > input:checked:disabled + span::before {
  background-color: #bfdbda;
 }
 
 .cl-switch > input:checked:disabled + span::after {
  background-color: lime;
 }