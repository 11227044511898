/* Token page */
/* Order */
.tokenPage {
  color: lime !important;
  width: 90% !important;
}
.left-column{
  width: 70%;
}
.chart-order{
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-top: 2%;
}
.chart {
  background-color: #101010!important;
  width: 100% !important;
  height: 80vh;
  color: lime !important;
  border-radius: .5em;
  margin: 5px;
}
.right-column{
  width: 27%;
}
.card-order{
  background-color: #101010 !important;
  width: 100% !important;
  color: lime !important;
  border-radius: .5em;
  margin: 5px;
}
.orderChoice {
  margin: 5% auto;
  display: flex;
  justify-content: space-evenly;
  width: 100%;
}
.selected {
  color: lime !important;
  border-bottom: 3px solid lime !important;
}
.unselected {
  color: white !important;
}
.website-container {
  background-color: #101010 !important;
  width: 100% !important;
  color: lime !important;
  border-radius: .5em;
  margin: 5px;
  padding-left: 10%;
}
.website-header{
  color: lime !important;
  text-align: left;
  width: 100%;
}
.website{
  text-align: left;
  width: 100%;
  padding: 0px 0px 15% 15%;
}
.description {
  text-align: left;
  width: 100%;
  padding: 0px 5% 5% 0%;
}
.stats-card{
  background-color: #101010 !important;
  width: 100% !important;
  border-radius: .5em;
  margin: 5px;
}

.stats-card-data{
  display: flex;
  justify-content: space-between;
  width: 100%;
  color: lime;
}
.stat-item {
  padding: 3%;
}

.time-period-buttons{
  display: flex;
  justify-content: space-evenly;
  width: 100%;
  margin: 0;
  padding: 0;
  font-size: 1.25em;
}

.coin-stats-card {
  background-color: lime !important ;
  display: flex !important;
  width: 100% !important;
  justify-content: space-between;
  border-radius: .5em;
}
.header-item{
  color: black;
  margin: 2%;
  font-weight: 700;
}

@media screen and (max-width:750px){
  .left-column{
      width: 100%;
  }
  .chart-order{
      flex-direction: column;
  }
  .right-column{
      width: 100%;
  }
  .chart {
    height: 50vh;
  }
}
@media screen and (max-width:890px){
  .coin-stats-card{
      flex-direction: column;
  }
}

/* Stat

.coin-link a{
  color: var(--pink);
  font-weight: 700;
  font-size: 1rem;
}

.coin-link:hover, .coin-stats:hover{
  background-color: var(--bgSecondary);
}

@media screen and (max-width:1000px){
  .stats-container{
      flex-direction: column;
  }
  .coin-desc-link{
      flex-direction: column;
  }
  .stats-container h2{
      margin-top: 0px;
  }
}

@media screen and (max-width: 500px){
  .coin-links{
    padding: 0px;
  }
  .coin-detail-container{
    margin: 0;
  }
}
@media screen and (max-width: 500px){
  .heading{
    margin-top: 20px;
  }
}

*/
/* Cryptocurrency Page */
.column-title{
  display: flex;
  justify-content: space-between;
  width: 90%;
  margin-left: 8%
}







  /* ETHDEX */

  .accountsPage {
    width: 100% !important;
    align-items: center !important;
    align-self: center !important;
  }
  .selectedArrayButtons{
    display: flex;
    justify-content: space-evenly;
    margin: 5%
  }

  .selectedArray{
    background-color: transparent;
    color:  white;
    border-radius: .5em;
    border: black;
    font-size: 1em;
  }
  
  .ETHDEX {
    width: 100% !important;
    align-items: center !important;
    /*
    align-self: center !important;
    background: linear-gradient(
        180deg,
        rgba(7, 8, 21, 1),
        #1e5100 1%,
        rgba(7, 8, 21, 1) 
        ) !important;
        */
  } 
  
  .searchBar {
    margin: 40px auto !important;
    background-color: #202020 !important;
    color: #909090 !important;
    text-align: center !important;
    line-height: 2em !important;
  }
  
  .heading {
    color: lime !important;
    text-align: center !important;
  }
  
  .column1 {
    float: left !important;
    margin-right: 30% !important;
    margin-left: 10% !important;
  }
  
  .column2 {
    float: left !important;
    margin-right: 20% !important;
  }
  
  .cardContainer {
    display: flex !important;
    align-items: center !important;
    justify-content: space-between !important;
    cursor: pointer !important;
  }
  
  .daoCard {
    background-color: #202020 !important;
    width: 90% !important;
    color: lime !important;
    border: 1px solid #202020 !important;
    margin: 5px auto !important;
  }
  
  .symbol {
    color: lime !important;
  }
  
  .logo {
    height: 32px !important;
    width: 32px !important;
    float: left !important;
  }
  .logoGRL{
    width: 4vw !important;
    float: left !important;
  }

  .amount {
    color: lime;
  }
  
  .name {
    font-weight: 600 !important;
    font-size: 15px !important;
    line-height: 14px !important;
    color: lime !important;
  }
  
  .type {
    font-weight: 600 !important;
    font-size: 15px !important;
    padding: 20px !important;
    color: lime !important;
  }
  
  .lastPrice {
    font-weight: 600 !important;
    font-size: 15px !important;
    padding: 20px !important;
    color: lime !important;
  }
  
  .favorites {
    float: right  !important;
    top: 10% !important;
    font-size: 125% !important;
  }
  
  @media screen and (max-width: 750px) {
    .daoCard {
      background-color: #202020 !important;
      width: 100% !important;
      color: lime !important;
      border: 1px solid #202020 !important;
      margin: 0px auto !important;
    }
  }

/* Home */

.homePage{
  height: 100%
}

.missionStatement {
  font-size: 2em;
  color: white;
  border-top: 2px white solid;
}

.USdebtClock {
  width: 30vw;
  margin-top: 4%;
  margin-bottom: 4%;
  border-radius: .5em
}
.subtitle {
  font-size: 1.5em;
  color: white;
  text-align: left;
  margin-bottom: 10px
}
.city {
  height: 100vh;
  width: 90vw;
  border-radius: .5em;
}
.cityContainer {
  width: 100%;
  justify-content: center;
  margin-bottom: 4%
}
.cityText {
  color: white;
  font-size: 2em;
  margin-bottom: 2%;
}
.fungibleTokens {
  color: white;
  background: transparent;
  border: none;
  display: flex;
  width: 80%;
  margin: 0px auto;
  margin-bottom: 10%;
  margin-top: 3%
}
.fungibleTokenItem {
  width: 50%;
  float: left;
  height: 70vh
}
.futureDevelopments{
  color: white;
  width: 27%;
  float: left;
  margin: 3%;
  margin-top: 5%
}
.futureDevelopmentPic {
  height: 30vh;
  width: 20vw;
  color: lime;
  font-size: 12em;
  border-radius: .5em;
}
.futureDevelopmentTitle {
  font-size: 2em;
  color: white;
}

@media screen and (max-width: 750px) {
  .homePage{
    width: 100%;
    margin: 0% auto;
  }
  .city {
    height: 50vh;
    width: 80%;
    border-radius: .5em;
  }
  .futureDevelopmentPic {
    height: 40vh;
    width: 80%;
  }
  .futureDevelopments{
    width: 90%;
    margin: 0% auto;
    margin-top: 30%;
  }
  .futureDevelopmentPic {
    height: 30vh;
    width: 90%;
    color: lime;
    font-size: 12em;
    border-radius: .5em;
    margin: 0% auto;
    margin-top: 20%
  }
  .USdebtClock {
    width: 100%;
    margin: 0% auto;
    margin-top: 40%;
    margin-bottom: 0%;
  }
  .fungibleTokenItem {
    width: 90%;
    float: none;
    margin: 3% auto;
    margin-bottom: 0%
  }
  .fungibleTokens {
    margin: 0% auto;
  }
}

/* Admin */

.chain-buttons{
  width: 100%;
  display: flex;
  justify-content: space-between;
  color: lime;
  border-radius: 2px black solid;
  margin-bottom: 5%
}

.selectChain{
  display: flex;
  justify-content: space-evenly;
}

.chain{
  font-size: 1.25em;
}

.selectedChain{
  font: bold;
  font-size: 1.5em;
  border-bottom: 3px solid lime;
}
.chain:hover{
  cursor: pointer;
  color: green;
}

.selectedChain:hover{
  cursor: pointer;
  color: green;
  border-bottom: 3px solid green;
}
.selectedArray:hover{
  color: #707070;
  cursor: pointer;
}
.filters{
  color: white;
}
.selectedArray.underline {
  text-decoration: underline;
  font-size: 1.1em
}

.transactions{
  position: flex;
  justify-content: center;
}
